<script >
export default {
  name: 'Course3',

  created() {
    document.title = '美成在久'
  }

}
</script>

<template>
  <iframe src="https://download.zjeav.com/eav-web/mczj/start.html" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>
</template>

<style scoped lang="less">
iframe {
  width: 100%;
  height: 100%;
}
</style>